.search-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .search-label {
        margin: 0 pxToRem(55);

        @include medium-or-smaller {
            width: 100%;
            margin: 0 0 pxToRem(13);
        }
    }

    .search-term {
        width: 50%;

        @include medium-or-smaller {
            flex-grow: 1;
            font-size: pxToRem(25);
        }

        &, &::placeholder {
            font-size: pxToRem(18);

            @include medium-or-larger {
                font-size: pxToRem(26);
            }
        }
    }
}

.search-form,
.tx-solr-faceting {
    margin-bottom: 2em;
}

.facet {
    fieldset {
        display: block;
    }

    legend {
        @include font($defaultFontFamily, 'regular');
    }
}

.spatial-search h3, .select-single h3 {
    @include font($defaultFontFamily, 'semi-bold');
    font-size: pxToRem(20);
}

.select-single {
    fieldset {
        margin: 0;
        border: 0;
        padding: 1em 0 2em 0;
    }
}

.facet-area-main .select-single {
    display: inline;
}

// class name is automatically created from facet name
.facet-name-specialOpeningTimes { // stylelint-disable-line selector-class-pattern
    grid-area: opening-times;
}

.facet-name-services {
    grid-area: services;

    fieldset {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }

    .facet {
        display: flex;
        align-items: center;
        margin: 0 1.5em 0 0;
    }
}

.search-results {
    --type-color: #{$themeColor};

    .result-color-pages {
        --type-color: #{$actionColor};
    }

    .result-color-tx_adeg_recipe {
        --type-color: #{$orange};
    }

    .result-color-tx_adeg_merchants {
        --type-color: #{$alternativeColor};
    }

    .result-color-tx_news_domain_model_news {
        --type-color: #{$lightGreen};
    }

    .facet-option-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include medium-or-smaller {
            flex-wrap: nowrap;
            max-width: 100%;
            overflow: auto;
        }

        .facet-option {
            margin-right: 1em;
        }
    }

    .facets-and-sorting {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2em;

        .sorting-and-results-count {
            display: flex;
            align-items: center;

            @include medium-or-smaller {
                flex-direction: column;
                align-items: flex-start;
                max-width: 100%;
            }

            .facet-type-options {
                @include medium-or-smaller {
                    max-width: 100%;
                }
            }
        }

        .tx-solr-sorting {
            text-align: right;

            .header-subtitle {
                margin-bottom: 0.5em;
            }

            .select-single {
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .small-rounded-button.facet {
            border-color: var(--type-color);
            border-width: 2px;
            border-radius: pxToRem(50);
            padding: pxToRem(8) pxToRem(19);
            font-size: pxToRem(18);
            font-weight: 400;
            line-height: 1.1;

            &.active, &:hover {
                background-color: var(--type-color);
            }

            .facet-result-count {
                margin-left: pxToRem(25);
            }
        }
    }

    .search-result-count {
        @include font($defaultFontFamily, 'semi-bold');

        margin: 0 pxToRem(38) 0 0;
        font-size: pxToRem(25);
        font-weight: fontWeight('semi-bold');
        text-align: left;

        @include medium-or-smaller {
            margin-bottom: 1rem;
        }
    }

    .page-navigation {
        text-align: center;

        ul > li {
            @include font($highlightFontFamily, 'bold');

            display: inline-block;

            &.current {
                padding: pxToRem(18) pxToRem(28);
                color: $themeColor;
            }

            &.pagination-spacer {
                padding: pxToRem(18) pxToRem(28);
            }

            a {
                display: block;
                border: pxToRem(1) solid $backgroundColorDark;
                padding: pxToRem(18) pxToRem(28);

                &:not(:hover) {
                    text-decoration: none;
                }
            }

            .button {
                min-width: initial;
                border-color: $themeColor;

                &:hover {
                    border-color: $backgroundColorDark;
                }
            }
        }
    }

    .result-list {
        .result-item {
            position: relative;
            cursor: auto;

            .result-type-label {
                position: absolute;
                top: pxToRem(10);
                left: pxToRem(10);
                border-radius: 50px;
                padding: pxToRem(6) pxToRem(17);
                color: $white;
                background-color: var(--type-color);
                font-size: pxToRem(18);
                font-weight: 900;
                line-height: 1;
            }

            .panel-header {
                margin: 1.9rem 1.4rem 0.9rem;
                hyphens: auto;

                h3.search-result-title {
                    margin: 0;

                    font-size: pxToRem(25);
                    line-height: pxToRem(30);
                }
            }

            .panel-content {
                margin: 0 1.4rem 4.8rem;

                .more-link {
                    position: absolute;
                    right: pxToRem(25);
                    bottom: pxToRem(25);
                    text-decoration: none;

                    i {
                        margin-left: pxToRem(12);
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .icon-row-wrapper {
                    margin-bottom: pxToRem(26);

                    .icon-row {
                        display: flex;

                        i {
                            flex-shrink: 0;
                            width: 2rem;
                            font-size: pxToRem(20);
                        }

                        +.icon-row {
                            margin-top: pxToRem(12);
                        }
                    }
                }
            }
        }
    }
}

@include medium-or-larger {
    .search-form .button {
        max-width: unset;
    }
}

@include medium-or-smaller {
    .button.search-button {
        min-width: auto;
        padding: pxToRem(18) pxToRem(23);

        i {
            line-height: 1.5;
        }
    }
}
