.merchant-search-form .tx-solr-faceting {
    margin-bottom: 0;
}

.merchant-search-form {
    margin: 1em 0 2em;

    @include medium-or-larger {
        display: grid;
        grid-template: 'search search' auto
            'services services' auto
            'opening-times cookie-box' auto
            'spatial cookie-box' 1fr / 1fr 1fr;
    }

    .cookie-consent-infotext {
        grid-area: cookie-box;
        margin-top: 1em;
    }

    #facet-wrapper, #filter-wrapper {
        margin-top: pxToRem(35);
    }

    #facet-wrapper {
        display: flex;
        flex-wrap: wrap;
        grid-area: services;
        align-items: center;

        .facet {
            margin-right: pxToRem(20);

            .dropdown-toggle {
                min-width: pxToRem(250);
            }
        }

        fieldset {
            margin: 0;
            padding: 0;
        }

        .facet-name-offers, .facet-name-special-offers {
            order: 1;
        }

        .facet.facet-name-special-offers {
            margin-right: 0;
            margin-left: auto;
        }

        .facet-icon {
            display: flex;
            gap: pxToRem(45);
            align-items: center;
            padding: 0;

            label {
                display: flex;
                align-items: center;
                margin: 0;
            }

            input[type='checkbox'] {
                width: pxToRem(25);
                height: pxToRem(25);
                margin-right: pxToRem(10);
            }
        }

        .facet-dropdown {
            input[type='checkbox'] {
                width: 20px;
                height: 20px;
                border: 1px solid $black;
                border-radius: 50%;
                vertical-align: middle;
                outline: none;
                cursor: pointer;
                appearance: none;

                &:checked {
                    background-color: $themeColor;
                    appearance: auto;
                    clip-path: circle(50% at 50% 50%);

                    & + label {
                        color: $themeColor;
                    }
                }
            }
        }

        .mobile-header, .mobile-filter-close-wrapper {
            display: none;
        }

        @include medium-or-smaller {
            display: none;

            &.active {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
                width: 100vw;
                height: 100%;
                margin: 0;
                overflow: scroll;
                background-color: $white;
            }

            .mobile-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 0.5rem 1rem;
                color: $white;
                background-color: $themeColor;
            }

            .mobile-filter-close {
                padding: 0 0.5rem;
                color: $white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .mobile-filter-close-wrapper {
                display: block;
                align-self: end;
                order: 10;
                margin-top: 1rem;

                a.mobile-filter-close {
                    padding: pxToRem(19) 1rem;
                    color: $themeColor;
                }
            }

            .facet {
                width: 100%;
                margin: 0;
                padding: pxToRem(19) 1rem;

                fieldset {
                    width: 100%;

                    &.facet-icon {
                        justify-content: space-around;
                    }
                }

                .dropdown {
                    width: 100%;

                    .dropdown-toggle {
                        width: 100%;
                        min-width: auto;
                    }

                    .dropdown-content {
                        position: static;
                        border: none;
                    }
                }

                &.facet-name-special-offers {
                    margin-right: auto;
                }
            }
        }
    }

    #filter-wrapper {
        display: flex;
        grid-area: services;
        grid-row: 10;
        align-items: center;

        .filter-headline {
            margin: 0 pxToRem(25) 0 0;

            @include medium-or-smaller {
                display: none;
            }
        }

        #filter-buttons {
            display: flex;
            flex-wrap: wrap;

            .filter-button {
                position: relative;
                margin: pxToRem(5) pxToRem(25) pxToRem(5) 0;
                border: none;
                border-radius: pxToRem(20);
                padding: 0 pxToRem(56) 0 pxToRem(18);
                color: $white;
                background-color: $themeColor;
                font-size: pxToRem(18);
                line-height: pxToRem(40);
                cursor: pointer;

                &::after {
                    position: absolute;
                    right: 1rem;
                    margin: 0;
                    border: none;
                    font-family: 'Font Awesome 6 Pro', serif;
                    font-size: pxToRem(20);
                    font-weight: 300;
                    content: '\f00d';
                }
            }
        }
    }
}

.merchant-list {
    padding: 0;

    .merchant {
        display: block;
        margin-bottom: pxToRem(12);
        border: 1px solid $borderColorDarker;
        padding: pxToRem(24) pxToRem(24) pxToRem(18);
        font-size: pxToRem(16);
        line-height: pxToRem(22);

        a {
            font-weight: 400;
            letter-spacing: normal;
            text-transform: none;
        }

        .merchant-header {
            font-weight: 700;
        }

        address, .telephone {
            margin-bottom: pxToRem(12);
            letter-spacing: normal;
        }

        i {
            width: pxToRem(25);
            margin-right: pxToRem(12);
            color: $themeColor;
            font-size: pxToRem(20);
        }

        &.active {
            border-color: $themeColor;
            border-width: 5px;
            padding: pxToRem(20) pxToRem(20) pxToRem(14);
        }

        .merchant-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: pxToRem(5);

            .link-to-map {
                padding: 0;
            }

            .toggle-dropdown {
                i {
                    margin-right: 0;
                }
            }
        }

        .merchant-dropdown {
            margin-top: pxToRem(20);

            .merchant-teaser {
                margin-bottom: pxToRem(20);
            }

            .detail-link {
                text-align: right;

                i {
                    margin-right: 0;
                    margin-left: 0.5rem;
                }
            }
        }

        .merchant-data-wrapper {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }

        & > div {
            margin: 0;
            border: none;
            padding: 0;
            background-color: transparent;
        }
    }
}

ul.merchant-services, .rte-contents ul.merchant-services {
    color: $themeColor;

    li {
        position: relative;
        padding: pxToRem(6) 0 pxToRem(6) 2rem;
        text-indent: 0;

        &::before {
            position: absolute;
            left: 0;
            background: none;
            font-family: 'Font Awesome 6 Pro', serif;
            font-size: pxToRem(20);
            font-weight: 700;
            content: '\f058';
        }
    }
}

.map-info {
    @include font($defaultFontFamily, 'regular');

    min-width: pxToRem(360);
    margin: 1em;
    font-size: pxToRem(16);

    // stylelint-disable-next-line no-descending-specificity
    & > div {
        margin-bottom: 0.5em;
    }

    .route {
        display: flex;
        justify-content: space-between;
    }

    .header {
        margin-bottom: 1em;
        text-align: left;

        a {
            color: $textColor;
            font-size: pxToRem(18);
        }
    }

    // stylelint-disable-next-line no-descending-specificity
    a {
        @include font($highlightFontFamily, 'bold');

        font-size: pxToRem(18);
        letter-spacing: 0.01em;
        text-transform: uppercase;
        text-decoration: none;

        &:hover, &:active {
            text-decoration: underline;
        }
    }

    .opening-hours {
        margin-bottom: 1em;
    }
}

.merchant-search-results {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1em;

    h2 {
        font-size: pxToRem(25);

        @include small-or-larger {
            font-size: pxToRem(45);
        }
    }

    button {
        height: 100%;
    }
}

.merchant-search {
    display: flex;
    flex-direction: column;
    grid-area: search;

    @include medium-or-larger {
        flex-direction: row;
        align-items: center;
    }

    .merchant-search-headline {
        display: flex;
        justify-content: space-between;

        .search-headline {
            margin: 0 2rem 0 0;
        }

        #mobile-filter-open {
            font-weight: 700;
            text-align: right;
            text-decoration: none;

            @include medium-or-larger {
                display: none;
            }
        }

        @include medium-or-smaller {
            flex-wrap: wrap;
            margin-bottom: 0.5rem;
        }
    }
}

.spatial-search {
    legend {
        @include font($defaultFontFamily, 'regular');

        .sub-legend {
            white-space: normal;
        }
    }
}

.search-results-wrapper {
    display: flex;
    gap: pxToRem(20);

    @include medium-or-larger {
        height: 700px;
    }

    @include medium-or-smaller {
        flex-direction: column;
    }

    #map, .cookie-consent-infotext {
        flex: 2;
        width: 100%;

        @include medium-or-smaller {
            flex: none;
            width: auto;
            height: pxToRem(320);
        }
    }

    .merchant-list-container {
        flex: 1;
        height: 100%;
        overflow: scroll;

        @include medium-or-smaller {
            flex: none;
            height: pxToRem(450);
        }
    }
}

.autocomplete-container {
    display: inline-block;
    width: 100%;

    @include medium-or-larger {
        width: 60%;
    }
}

// stylelint-disable selector-class-pattern
.autocomplete__menu {
    position: absolute;
    max-height: initial;
    overflow-y: hidden;
    visibility: visible;
    opacity: 1;

    @include large-or-larger {
        width: 528px;
    }

    &.autocomplete__menu--inline {
        position: absolute;
        z-index: 2;
        width: 100%;
    }

    .autocomplete__option {
        display: flex;
        margin: 0;
        padding: 10px 15px;

        @media (min-width: 361px) and (min-height: 641px) {
            padding: 18px 25px;
            font-size: 18px;
        }

        &::before {
            border-width: 0;
        }

        img {
            width: 18px;
            height: 25px;
            margin-right: pxToRem(10);
        }
    }

    .autocomplete__option--focused,
    .autocomplete__option:hover {
        img {
            filter: brightness(8);
        }
    }
}

.autocomplete__wrapper {
    .autocomplete__input {
        height: pxToRem(69);
        margin-right: 0;
        font-size: pxToRem(26);

        @include medium-or-smaller {
            height: pxToRem(50);
            font-size: pxToRem(18);
        }
    }
}

.autocomplete__hint {
    display: none;
}
