.merchant {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacingExtraSmall;

    @include small-or-larger {
        margin-bottom: $spacingSmall;
    }

    @include medium-or-larger {
        flex-direction: row;
        margin-bottom: $spacingMedium;
    }

    @include large-or-larger {
        margin-bottom: $spacingLarge;
    }

    @include extra-large-or-larger {
        margin-bottom: $spacingExtraLarge;
    }

    & > * {
        flex: 1 1 50%;

        @include extra-large-or-larger {
            flex-basis: 620px;
        }
    }

    & > span {
        margin-bottom: $spacingExtraSmall;
        line-height: 0;

        @include small-or-larger {
            margin-bottom: $spacingSmall;
        }

        @include medium-or-larger {
            margin-right: $spacingMedium;
            margin-bottom: 0;
        }

        @include large-or-larger {
            margin-right: $spacingLarge;
        }

        @include extra-large-or-larger {
            margin-right: $spacingExtraLarge;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    & > div {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: $spacingExtraSmall;
        border: 1px solid $borderColor;
        padding: 1.5em;
        background-color: $backgroundColor;

        @include medium-or-larger {
            margin-bottom: 0;
            padding: 2.5em;
        }
    }

    h1, h3 {
        margin-bottom: 1em;
        font-size: pxToRem(22);
    }

    h1 {
        text-align: left;

        @include small-or-larger {
            font-size: pxToRem(30);
        }
    }

    h3 {
        a {
            color: $textColor;
            letter-spacing: 0.005em;
            text-transform: uppercase;
        }
    }

    address {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-style: normal;

        @include very-small-or-larger {
            flex-direction: row;
        }

        & > img {
            margin-right: 1em;
            margin-bottom: 1em;
        }

        & > div {
            word-break: break-word;
            overflow-wrap: anywhere;

            @include very-small-or-larger {
                margin-bottom: 1em;
                line-height: 2;
            }

            @include large-or-larger {
                word-break: normal;
                overflow-wrap: normal;
            }
        }
    }

    // stylelint-disable-next-line no-descending-specificity
    a {
        @include font($highlightFontFamily, 'bold');
        letter-spacing: 0.01em;

        text-transform: uppercase;
        text-decoration: none;

        &:hover, &:active {
            text-decoration: underline;
        }
    }

    .telephone {
        margin-bottom: 1em;
        letter-spacing: 0.1em;
    }

    .link-to-map {
        margin-top: auto;
        padding-top: 1em;
    }
}

.merchant-speciality {
    h2 {
        font-size: pxToRem(22);
        text-align: left;

        @include small-or-larger {
            font-size: pxToRem(30);
        }
    }

    ul {
        @include content-ul();

        @include medium-or-larger {
            flex-direction: row;
        }

        columns: 1;

        @include small-or-larger {
            columns: 2;
        }
    }
}

.opening-hours {
    margin-bottom: 1em;

    dt, dd {
        float: left;
        margin: 0;
        padding: 0;
    }

    dt {
        clear: both;
        min-width: 2em;
    }

    span {
        white-space: nowrap;
    }
}
