.panel-list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 2.5em;
    padding-left: 0;

    @include small-or-larger {
        flex-direction: row;
    }
}

.panel-container {
    width: 100%;
}

.panel {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 1em;
    cursor: pointer;

    @include small-or-larger {
        flex: 0 1 49%;
        margin-right: 2%;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    @include large-or-larger {
        flex: 0 1 32%;

        &:nth-child(2n) {
            margin-right: 2%;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.panel-list-container.panel-list-4-cols {
    .panel {
        @include extra-large-or-larger {
            flex: 0 1 23.5%;

            &:nth-child(3n) {
                margin-right: 2%;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

.panel-header {
    h3, h4 {
        a {
            @include font($highlightFontFamily, 'bold');

            color: $themeColor;
            font-size: pxToRem(20);
            letter-spacing: 0.01em;
            text-transform: uppercase;

            &:not(:hover) {
                text-decoration: none;
            }
        }
    }
}

.panel-image {
    order: -1;
    overflow: hidden;
    font-size: 0;

    img {
        width: 100%;
        height: auto;
        transition: transform 0.5s ease;

        &:hover {
            transform: scale(1.1);

            @media (prefers-reduced-motion: reduce) {
                transform: none;
            }
        }
    }
}

.panel-show-more {
    width: 100%;
    margin-top: 5rem;
}

@media print {
    .panel-show-more {
        display: none;
    }
}
