.form-select-single,
.form-select-multi {
    position: relative;
    display: block;
    min-width: 100%;

    @include small-or-larger {
        max-width: 50%;
    }

    select {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 0.77rem;
        font-size: 1.25rem;

        option {
            min-height: 1.5em;
        }

        option[value=''], &:invalid {
            color: $formElementColor;
        }
    }
}

main form {
    * {
        box-sizing: border-box;
    }

    > .form-group {
        margin-bottom: 2rem;
    }

    fieldset {
        @include small-or-larger {
            margin-right: -2rem;
        }

        > .form-group {
            min-width: 100%;
            margin-bottom: 2rem;

            @include small-or-larger {
                min-width: 50%;
                padding-right: 2rem;
            }

            .form-select-single,
            .form-select-multi {
                min-width: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .full-line {
            width: 100%;
        }
    }

    fieldset,
    .row {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 0;
        padding: 1rem 0 0 0;
    }

    input[type='radio'], input[type='checkbox'] {
        width: 1em;
        height: 1em;
        margin-right: 0.25em;
        vertical-align: baseline;

        &:focus {
            outline-offset: 3px;
        }
    }

    input[type='file'] {
        display: none;
    }

    label {
        display: inline-block;
        margin-bottom: 0.5em;
    }

    textarea {
        width: 100%;
        height: 20ex;
    }

    .row {
        @include small-or-larger {
            margin-right: -2rem;
        }

        > .column {
            flex-grow: 1;
            width: 100%;

            @include small-or-larger {
                max-width: 50%;
                padding-right: 2rem;
            }

            > .form-group {
                margin-bottom: 2rem;
            }
        }
    }
}

.input {
    width: 100%;

    &.fileupload .help-block {
        display: block;
        margin-top: 0.5em;
    }

    input {
        width: 100%;
    }
}

.upload-button {
    max-width: unset;
    border-color: #000;
    color: $textColor;
    background-color: transparent;

    &:hover, &:active {
        border-color: $themeColor;
        color: $white;
        background-color: $themeColor;
    }
}

.upload-filename {
    margin-left: 0.5em;
}

.form-container-horizontal {
    .inputs-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .radio,
    .form-check {
        margin-right: 1.5em;
    }
}

.form-navigation > .btn-toolbar > .btn-group {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .previous,
    .next,
    .submit {
        flex-grow: 1;

        .button {
            max-width: initial;
        }
    }

    .next,
    .submit {
        text-align: right;
    }
}

.sub-legend {
    display: block;
    color: $formElementColor;
    font-size: pxToRem(20);
}
