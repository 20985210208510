.pointer {
    cursor: pointer;
}

.block-link {
    position: relative;
}

.block-link-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 200%; // move text out of visible area
    visibility: visible;
}

.cookie-consent-infotext {
    border: 2px solid $themeColor;
    padding: 1.5em;
    background-color: $white;
    font-size: pxToRem(16);

    @include small-or-larger {
        font-size: pxToRem(18);
    }

    h3 {
        @include font($defaultFontFamily, 'semi-bold');

        margin-bottom: 0.5em;
        color: $themeColor;
        font-size: pxToRem(18);
        letter-spacing: 0.005em;
        text-transform: uppercase;

        @include small-or-larger {
            font-size: pxToRem(20);
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    .button {
        font-size: pxToRem(16);

        @include small-or-larger {
            font-size: pxToRem(18);
        }
    }
}

.issuu-fallback-wrapper {
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        filter: blur(0.5rem);
    }
}

.cookie-consent-wrapper {
    position: relative;

    .cookie-consent-infotext {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.cookie-consent-link {
    @include underline(true);

    border: 0;
    padding: 0;
    color: $linkColor;
    background-color: transparent;
    font-size: pxToRem(16);
    font-weight: fontWeight('semi-bold');
    cursor: pointer;

    @include small-or-larger {
        font-size: pxToRem(18);
    }
}

.cookie-consent-header {
    @include font($highlightFontFamily, 'bold');

    margin: 0 0 0.5em;
    font-size: pxToRem(18);
    letter-spacing: 0.01em;
    text-transform: uppercase;

    @include small-or-larger {
        font-size: pxToRem(20);
    }
}

.running-section-wrapper {
    @include centerPaddedHorizontallyConstrained();
}

@media print {
    :root {
        font-size: 11pt;
    }

    body > aside {
        display: none;
    }

    main {
        margin: 0;
    }
}
