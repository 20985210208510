.ce-additional {
    padding: 2.5em 0;
    color: $white;
    background-color: $backgroundColorDark;

    a {
        color: $white;
    }
}

.additional-information-boxes {
    display: flex;
    flex-direction: column;

    @include small-or-larger {
        flex-direction: row;
    }

    h3 {
        flex: auto;
        font-weight: fontWeight('bold', $highlightFontFamily);

        @include medium-or-larger {
            font-size: pxToRem(36);
        }
    }

    a {
        font-weight: fontWeight('semi-bold');
        letter-spacing: 0.005em;
        text-transform: uppercase;

        &:not(:hover) {
            text-decoration: none;
        }
    }

    input {
        box-sizing: border-box;
        margin-bottom: 1.5em;
        background-color: $white;

        &[type='email'] {
            width: 100%;
        }

        &[type='submit'] {
            @extend a;
            margin-bottom: 0;
            border: 0;
            color: $linkColor;
            background-color: $backgroundColor;
            white-space: normal;
        }
    }

    & > div {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        margin-bottom: $spacingSmall;
        border: 1px solid $borderColor;
        padding: 1.5em;
        background-color: $backgroundColor;
        text-align: center;

        @include small-or-larger {
            flex: 1;
            margin-right: $spacingMedium;
            margin-bottom: 0;
        }

        @include medium-or-larger {
            margin-right: $spacingLarge;
        }

        @include large-or-larger {
            margin-right: $spacingExtraLarge;
        }

        img {
            margin-bottom: pxToRem(19);
        }
    }

    & > div:last-child {
        margin-bottom: 0;

        @include small-or-larger {
            margin-right: 0;
        }
    }
}

aside {
    margin-bottom: 6.5em;
}

aside .ce-div,
.ce-additional {
    margin-bottom: $spacingExtraSmall;

    @include small-or-larger {
        margin-bottom: $spacingSmall;
    }

    @include medium-or-larger {
        margin-bottom: $spacingMedium;
    }

    @include large-or-larger {
        margin-bottom: $spacingLarge;
    }

    @include extra-large-or-larger {
        margin-bottom: $spacingExtraLarge;
    }
}

@media print {
    .additional-information-boxes {
        // stylelint-disable-next-line declaration-no-important
        display: none !important;
    }
}
