.news-single {
    $gapWidth: 2vw;

    .box-content {
        // grid is written to work with grid autoplacement of postcss-preset-env (IE11)
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        gap: $gapWidth;

        @include medium-or-larger {
            grid-template-rows: 1fr;
            grid-template-columns: 2fr 1fr;
        }

        @include extra-large-or-larger {
            grid-template-rows: 1fr;
            grid-template-columns: 3fr 1fr;
        }
    }

    .main-content {
        margin-bottom: 3rem;
    }

    .news-img-wrap {
        img {
            width: 100%;
            height: auto;

            @include small-or-larger {
                width: auto;
                max-width: 100%;
                max-height: pxToRem(450);
            }
        }
    }

    .news-related-files {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;

            li {
                display: flex;
                min-width: 45%;
                margin: 0 1rem 2rem 0;
                padding: 0;

                &::before {
                    display: none;
                }
            }
        }

        img {
            display: flex;
            max-width: pxToRem(250);
            max-height: pxToRem(250);
            border: 1px solid $borderColorDarker;
        }
    }
}

.news-list-view {
    h3 {
        a {
            text-decoration: none;

            &:hover, &:active {
                text-decoration: underline;
            }
        }
    }

    .read-more {
        margin: 0;

        .button {
            padding: 0;
        }
    }

    .page-navigation {
        ul > li {
            @include font($highlightFontFamily, 'bold');

            display: inline-block;

            &.current {
                padding: pxToRem(18) pxToRem(28);
                color: $themeColor;
            }

            &.pagination-spacer {
                padding: pxToRem(18) pxToRem(28);
            }
        }

        // stylelint-disable-next-line no-descending-specificity
        a {
            display: block;
            border: pxToRem(1) solid $backgroundColorDark;
            padding: pxToRem(18) pxToRem(28);
            text-decoration: none;

            &:hover, &:active {
                text-decoration: underline;
            }
        }

        .button {
            min-width: initial;
            border-color: $themeColor;

            &:hover, &:active {
                border-color: $backgroundColorDark;
            }
        }
    }

    .article {
        margin-bottom: 3em;
    }

    .header-subtitle {
        margin-bottom: 0.5em;
    }

    .news-img-wrap {
        img {
            width: auto;
            max-height: pxToRem(300);
        }
    }
}
