p:not(.header-subtitle) {
    margin: 0 0 1em 0;

    // normalize browser rules
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ol {
    margin: 0 0 1em 0;
    padding: 0;

    & > li {
        margin: 0 0 0 1.25em;
        padding: 0 0 0.5em 0;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

ul {
    @include content-ul();
}

ol ol, ol ul, ul ul, ul ol {
    margin: 0.5em 0 0 0;
}

.text-center {
    text-align: center;
}

.highlight, .highlight-tradition {
    @include font($defaultFontFamily, 'semi-bold');

    color: $themeColor;
    font-size: pxToRem(20);

    @include medium-or-larger {
        font-size: pxToRem(24);
    }
}
