.button {
    @include font('GoldplayAlt', 'bold');

    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: pxToRem(190);
    border: pxToRem(2) solid $themeColor;
    padding: pxToRem(18) pxToRem(38);
    color: $white;
    background-color: $themeColor;
    font-size: pxToRem(20);
    line-height: 1.5;
    letter-spacing: 0.01em;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    @include medium-or-larger {
        max-width: 66%;
    }

    &:hover {
        color: $themeColor;
        background-color: $white;
    }

    &.secondary {
        min-width: initial;
        border: 0;
        padding: pxToRem(20) 0;
        color: $themeColor;
        background: none;
        text-align: left;

        &:hover, &:active {
            text-decoration: underline;
        }
    }

    &.white-on-darkgreen {
        border: 0;
        padding: pxToRem(20) pxToRem(40);
        color: $themeColor;
        background-color: $white;

        &:hover {
            color: $white;
            background-color: $themeColor;
        }
    }

    &.disabled {
        cursor: no-drop;
        opacity: 0.75;
        pointer-events: none;

        &:hover {
            color: $white;
            background-color: $themeColor;
        }

        &.secondary:hover, &.secondary:active {
            color: $themeColor;
            background: none;
            text-decoration: none;
        }

        &.white-on-darkgreen:hover {
            color: $themeColor;
            background-color: $white;
        }
    }

    &.form-submitted::before {
        margin-right: 1rem;
        font-family: 'Font Awesome 6 Pro', serif;
        animation: fa-spin 2s linear 0s infinite normal;
        content: '\f3f4';
    }
}

.small-rounded-button {
    @include font($defaultFontFamily, 'semi-bold');

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: pxToRem(1) solid $formElementColor;
    border-radius: pxToRem(18);
    padding: pxToRem(7) pxToRem(14);
    color: $textColor;
    background-color: $white;
    font-size: pxToRem(14);
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $white;
        background-color: $themeColor;
    }

    &.active {
        color: $white;
        background-color: $themeColor;
    }
}
