.select-single {
    display: flex;

    select {
        height: pxToRem(50);
        border: 1px solid $formElementColor;
        padding: 0 0.6em;
        color: $textColor;
        background-color: $white;
        font-size: pxToRem(18);

        option {
            color: $textColor;
        }

        option[value=''], &:invalid {
            color: $formElementColor;
        }
    }
}

.validation-error {
    margin-top: 0.5em;
    color: $actionColor;
    font-weight: fontWeight('semi-bold');
}
