#merchant-selector-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($black, 0.3);

    p {
        margin: 0 0 0.5rem;
    }

    #merchant-selector {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        width: 30vw;

        @include extra-large-or-smaller {
            width: 50vw;
        }

        @include large-or-smaller {
            width: 70vw;
        }

        @include small-or-smaller {
            width: 90vw;
        }

        @include very-small-or-smaller {
            width: 100vw;
        }

        padding: pxToRem(40) pxToRem(55);
        background-color: $white;

        #merchant-selector-headline {
            padding-right: 2.5rem;
        }

        #merchant-selector-form {
            display: flex;
            align-items: center;
            margin-bottom: 1.6rem;

            #merchant-selector-input, #merchant-search-term {
                flex-grow: 1;
                height: auto;
                font-size: 1rem;
            }

            // stylelint-disable selector-id-pattern
            #merchant-search-term__listbox {
                width: calc(100% - 2px);
                border-width: 1px;
            }
            // stylelint-enable selector-id-pattern

            #merchant-selector-icon {
                margin-left: 1rem;
            }
        }

        #merchant-selector-detail {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: pxToRem(40) pxToRem(55);
            background-color: $white;
        }

        .merchant-infos {
            margin-bottom: 1rem;

            i {
                width: pxToRem(20);
                margin-right: pxToRem(12);
            }
        }

        img.merchant-location {
            margin-bottom: 1rem;
        }
    }

    #merchant-selector-results {
        li {
            @include small-or-larger {
                margin-right: pxToRem(46);
            }
            margin-bottom: 0.75rem;
            border: 1px solid $formElementColor;
            padding: 1.5rem 1.5rem 0.5rem;
            hyphens: auto;

            a.bold-link {
                white-space: normal;
            }

            i {
                width: pxToRem(20);
                margin-right: pxToRem(12);
            }

            .merchant-selector-detail-link {
                display: inline-block;
                margin-bottom: 0.75rem;
            }
        }
    }

    .merchant-selector-toggle.toggle-float {
        position: absolute;
        top: pxToRem(40);
        right: pxToRem(55);
    }
}
