.ce-link {
    margin: 1.5em 0 2.5em 0;
}

.ce-media {
    $gapWidth: $spacingExtraSmall;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    .video-embed {
        position: relative;

        iframe {
            &.optanon-category-C0004 { // stylelint-disable-line selector-class-pattern
                display: none;
            }
        }

        .video-embed-item {
            box-sizing: border-box;
            width: 100%;
        }
    }

    & > * {
        margin-bottom: $gapWidth;
    }

    // tablet: 2 columns
    @media (min-width: 600px) {
        // stylelint-disable-next-line scss/no-duplicate-dollar-variables
        $gapWidth: $spacingSmall;

        & > * {
            margin-bottom: 0;
        }

        // classes with grids are written to work with grid autoplacement of postcss-preset-env (IE11)
        &.ce-cols {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $gapWidth;
        }

        &.ce-cols-3 {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media (min-width: 600px) and (max-width: 1024px) {
        img {
            height: 35vw;
            object-fit: cover;
        }

        .ce-col-span-tablet {
            grid-column: span 2;
            -ms-grid-column-span: 3; // the gap is created by a blank column for IE11
        }

        .ce-col-span-tablet + *:nth-child(2) {
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-row: 2; // fixes span in first row for IE11, but reverses image order in second row
        }
    }

    // desktop: 3 columns (fixed height to avoid scaling issues with different aspect ratios)
    @media (min-width: 1025px) {
        // stylelint-disable-next-line scss/no-duplicate-dollar-variables
        $gapWidth: $spacingMedium;

        img {
            width: auto;
            max-width: 100%;
            height: pxToRem(286);
            object-fit: cover;
        }

        &.ce-cols {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $gapWidth;
        }

        &.ce-cols-2.ce-left {
            grid-template-rows: 1fr;
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 2fr $gapWidth 1fr; // the gap is created by a blank column for IE11
        }

        &.ce-media-1.ce-cols-2.ce-left {
            grid-template-rows: 1fr;
            grid-template-columns: auto 1fr;
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: auto $gapWidth 1fr; // the gap is created by a blank column for IE11
        }

        &.ce-cols-2.ce-right {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr auto;
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 1fr $gapWidth auto; // the gap is created by a blank column for IE11
        }
    }

    @media (min-width: 1201px) {
        // stylelint-disable-next-line scss/no-duplicate-dollar-variables
        $gapWidth: $spacingLarge;

        img {
            height: pxToRem(326);
        }

        &.ce-cols {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $gapWidth;
        }

        &.ce-cols-2.ce-left {
            grid-template-rows: 1fr;
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 2fr $gapWidth 1fr; // the gap is created by a blank column for IE11
        }

        &.ce-cols-2.ce-right {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr auto;
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 1fr $gapWidth auto; // the gap is created by a blank column for IE11
        }
    }

    @media (min-width: 1441px) {
        // stylelint-disable-next-line scss/no-duplicate-dollar-variables
        $gapWidth: $spacingExtraLarge;

        img {
            height: pxToRem(368);
        }

        &.ce-cols {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $gapWidth;
        }

        &.ce-cols-2.ce-left {
            grid-template-rows: 1fr;
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 2fr $gapWidth 1fr; // the gap is created by a blank column for IE11
        }

        &.ce-cols-2.ce-right {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr auto;
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 1fr $gapWidth auto; // the gap is created by a blank column for IE11
        }
    }

    @media (min-width: 1601px) {
        // stylelint-disable-next-line scss/no-duplicate-dollar-variables

        img {
            height: pxToRem(408);
        }

        &.ce-cols {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $gapWidth;
        }

        &.ce-cols-2.ce-left {
            grid-template-rows: 1fr;
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 2fr $gapWidth 1fr; // the gap is created by a blank column for IE11
        }

        &.ce-cols-2.ce-right {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr auto;
            // stylelint-disable-next-line property-no-vendor-prefix
            -ms-grid-columns: 1fr $gapWidth auto; // the gap is created by a blank column for IE11
        }
    }
}

.ce-beside-image {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        text-align: left;
    }
}

.ce-below-image {
    margin-top: $spacingExtraSmall;

    @include small-or-larger {
        margin-top: $spacingSmall;
    }

    @include medium-or-larger {
        margin-top: $spacingMedium;
    }

    @include large-or-larger {
        margin-top: $spacingLarge;
    }

    @include extra-large-or-larger {
        margin-top: $spacingExtraLarge;
    }
}

.ce-highlight-box {
    box-sizing: border-box;
    padding: 1.5em;
    background-image: url(../images/highlight-box-background.jpg);
    background-position: center top;

    @include medium-or-larger {
        padding: 2.5em;
    }
}

.ce-banner {
    color: $white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 10em;

        @include medium-or-larger {
            width: 75%;
            margin: 0 auto;
        }
    }

    p {
        line-height: 1.3;
        text-shadow: 0 -1px 4px #0001, 1px 0 4px #0001, 0 1px 4px #0001, 1px 1px 4px #0001;

        &:not(.h2) {
            @include medium-or-larger {
                font-size: pxToRem(22);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: $white;

        &:not(:hover) {
            text-decoration: none;
        }

        &:hover {
            text-decoration-thickness: auto;
        }

        &:focus {
            outline-color: $white;
        }
    }

    img {
        align-self: center;
    }

    .h2 {
        line-height: 1;
    }
}
