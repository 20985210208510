.breadcrumbs {
    margin-bottom: 2em;
    padding-top: 1.5em; // padding to avoid margin collapse
    font-size: pxToRem(16);

    @include small-or-larger {
        font-size: pxToRem(18);
    }

    a:not(:hover) {
        text-decoration: none;
    }

    .arrow {
        margin: 0 0.3em;
    }

    ul, ol {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline;
        padding: 0;
        text-indent: 0;
    }
}

@media print {
    .breadcrumbs {
        display: none;
    }
}
