h1 {
    @include font($highlightFontFamily, 'bold');

    font-size: pxToRem(35);
    text-align: center;

    @include medium-or-larger {
        font-size: pxToRem(65);
    }
}

h2, .h2 {
    @include font($highlightFontFamily, 'bold');

    font-size: pxToRem(30);
    text-align: center;

    @include medium-or-larger {
        font-size: pxToRem(45);
    }
}

h3, .h3 {
    @include font($highlightFontFamily, 'semi-bold');

    font-size: pxToRem(26);

    @include medium-or-larger {
        font-size: pxToRem(38);
    }
}

h4, .h4 {
    @include font($highlightFontFamily, 'semi-bold');

    font-size: pxToRem(22);

    @include medium-or-larger {
        font-size: pxToRem(25);
    }
}

h5 {
    @include font($highlightFontFamily, 'semi-bold');

    font-size: pxToRem(20);
    letter-spacing: 0.005em;
    text-transform: uppercase;
}

h6 {
    @include font($highlightFontFamily, 'semi-bold');

    font-size: pxToRem(20);
}

ul, ul > li {
    padding: 0;
    list-style: none;
}

ul > li {
    margin: 0;
}

.header {
    text-align: center;
}

.header-subtitle {
    margin-bottom: $spacingExtraSmall;
    font-size: pxToRem(20);

    @include font($defaultFontFamily, 'semi-bold');

    @include small-or-larger {
        margin-bottom: $spacingSmall;
    }

    @include medium-or-larger {
        margin-bottom: $spacingMedium;
        font-size: pxToRem(24);
    }

    @include large-or-larger {
        margin-bottom: $spacingLarge;
    }

    @include extra-large-or-larger {
        margin-bottom: $spacingExtraLarge;
    }
}

h1 + .header-subtitle,
h2 + .header-subtitle,
h3 + .header-subtitle,
h4 + .header-subtitle,
h5 + .header-subtitle,
h6 + .header-subtitle {
    margin-top: -0.5 * $spacingExtraSmall;

    @include small-or-larger {
        margin-top: -0.5 * $spacingSmall;
    }

    @include medium-or-larger {
        margin-top: -0.5 * $spacingMedium;
    }

    @include large-or-larger {
        margin-top: -0.5 * $spacingLarge;
    }

    @include extra-large-or-larger {
        margin-top: -0.5 * $spacingExtraLarge;
    }
}

.header-lead {
    margin-top: 0;
}
