// Colors
$themeColor: #23872d; // CI green
$alternativeColor: #d2d700; // CI yellow
$actionColor: #dc4137; // CI red
$white: #fff;
$black: #000;
$textColor: $black;
$linkColor: $themeColor;
$orange: #fab400;
$lightGreen: #64b42d;
$backgroundColor: #f8faf7;
$backgroundColorDark: #1e3c1e;
$borderColor: #efefef;
$borderColorDarker: #d6d6d6;
$formElementColor: #767676;

// Fonts (Helvetica may be mapped to Nimbus Sans on Linux)
$fontFamilies: (
    'Goldplay': 'Goldplay, "Helvetica Neue", Helvetica, "Arial Nova", Arial, "Liberation Sans", sans-serif',
    'GoldplayAlt': 'GoldplayAlt, "Helvetica Neue", Helvetica, "Arial Nova", Arial, "Liberation Sans", sans-serif'
);
$fontWeights: (
    'Goldplay': (
        'regular': 400,
        'semi-bold': 600,
    ),
    'GoldplayAlt': (
        'semi-bold': 600,
        'bold': 700,
    )
);
$fontLetterSpacing: (
);
$defaultFontFamily: 'Goldplay';
$highlightFontFamily: 'GoldplayAlt';
$defaultFontStack: unquote(map-get($fontFamilies, $defaultFontFamily));
$highlightFontStack: unquote(map-get($fontFamilies, $highlightFontFamily));
$fa-font-path: '../fonts/fontawesome';

// Breakpoints
$breakpointHuge: pxToRem(1920);
$breakpointExtraLarge: pxToRem(1440);
$breakpointLarge: pxToRem(1280);
$breakpointMedium: pxToRem(1024); // small desktop / tablet (portrait)
$breakpointSmall: pxToRem(768); // tablet
$breakpointVerySmall: pxToRem(480); // large phone (eg Galaxy Note 5 or iPhone 7 Plus)
$breakpointTiny: pxToRem(375); // small phone (eg iPhone 8 or Galaxy S7, smaller than 360px are only very old phones)
$breakpointMenuExtraLarge: pxToRem(1500);
$breakpointMenuLarge: pxToRem(1370);

// Spacing
$spacingExtraLarge: pxToRem(28);
$spacingLarge: pxToRem(25);
$spacingMedium: pxToRem(23);
$spacingSmall: pxToRem(19);
$spacingExtraSmall: 1rem;

// Layout
$marginMobile: (
    'minimum': 5px,
    'value': 8vw,
    'maximum': 80px
);
