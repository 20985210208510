.footer {
    margin: 1em 0 0;
    background-color: $backgroundColor;

    a {
        text-decoration: none;

        &:hover, &:active {
            text-decoration: underline;
        }
    }

    ul {
        margin-bottom: 0;
    }

    li {
        list-style-type: none;
    }

    > * {
        display: flex;
        flex-direction: column;

        @include medium-or-larger {
            flex-direction: row;
        }
    }
}

.footer-top-menu {
    padding-top: 4em;

    & > div {
        flex: 1 1 auto;
        margin-bottom: 2.5em;
        padding-right: 1em;

        &:last-child {
            padding-right: 0;
        }
    }

    @include medium-or-larger {
        > div {
            flex: 1;
        }
    }

    h3 {
        margin-bottom: 1.5em;
        font-size: pxToRem(18);
        letter-spacing: 0.005em;
        text-transform: uppercase;

        @include small-or-larger {
            font-size: pxToRem(20);
        }
    }

    li {
        margin-bottom: 0.5em;
    }

    // stylelint-disable-next-line no-descending-specificity
    a {
        font-size: pxToRem(16);

        @include small-or-larger {
            font-size: pxToRem(18);
        }
    }
}

.footer-bottom {
    justify-content: space-between;
    padding-top: 2em;
    padding-bottom: 3rem;

    @include medium-or-larger {
        padding-top: 5.5em;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.footer-bottom-social-media {
    align-self: start;
    width: 100%;
    max-width: 500px; // in px as icons are sized in px as well
    line-height: 0;

    @include medium-or-larger {
        width: unset;
        max-width: unset;
    }

    ul {
        gap: 2.5rem;
        justify-content: start;

        @include medium-or-smaller {
            gap: 1rem;
        }

        @include very-small-or-smaller {
            gap: 0.5rem;
        }
    }
}

.footer-bottom-menu {
    margin-top: 1.5em;
    font-size: pxToRem(16);

    @include medium-or-larger {
        display: flex;
        margin-top: 0;
    }

    // stylelint-disable-next-line no-descending-specificity
    li {
        margin-right: 2.5em;

        @include medium-or-larger {
            margin-right: 0;
            margin-left: 2.5em;
        }
    }
}

.footer-accessibility-info {
    display: flex;
    flex-direction: column;
    padding-top: 0.5em;
    padding-bottom: 2em;

    @include medium-or-larger {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > * {
            padding-right: 2em;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    h2 {
        @include font($defaultFontFamily, 'regular');

        font-size: pxToRem(18);
        text-align: left;

        @include medium-or-larger {
            margin-bottom: 0;
        }
    }

    a:any-link {
        text-decoration: none;
    }

    a:hover, a:active {
        text-decoration: underline;
    }

    p {
        margin: 0;
        font-size: pxToRem(16);
        font-weight: fontWeight('regular');

        @include small-or-larger {
            font-size: pxToRem(18);
        }
    }

    img {
        margin-top: 1em;

        @include medium-or-larger {
            margin-top: 0;
        }
    }
}

.footer-content {
    margin-top: $spacingExtraSmall;

    @include medium-or-larger {
        margin-top: 0;
    }

    img {
        width: 100%;
        max-width: 380px; // this is the physical image size
        height: auto;
        margin-bottom: 0.5em;
    }
}

.footer-content-link {
    @include font($highlightFontFamily, 'semi-bold');
    letter-spacing: 0.005em;

    text-transform: uppercase;
}

@media print {
    .footer, .footer-accessibility-info {
        display: none;
    }
}
