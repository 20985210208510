::selection {
    color: $white;
    background-color: $themeColor;
}

html {
    scroll-padding-top: pxToRem(200);

    @include medium-or-smaller {
        scroll-padding-top: pxToRem(80);
    }
}

body {
    @include font($defaultFontFamily, 'regular');

    margin: 0;
    padding: 0;
    color: $textColor;
    background-color: $white;
    font-size: pxToRem(18);
    line-height: 1.5;
    -webkit-tap-highlight-color: rgba($white, 0); // Change the default tap highlight to be completely transparent in iOS (taken from Bootstrap).
    text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS (taken from Bootstrap).

    @include small-or-larger {
        font-size: pxToRem(20);
    }
}

a {
    color: $linkColor;
    text-decoration: underline;
    text-underline-offset: 0.1em;

    &:hover, &:active {
        text-decoration-thickness: 0.1em;
    }

    &[id] {
        scroll-margin-top: pxToRem(180);
    }
}

b, strong {
    font-weight: fontWeight('semi-bold'); // 700 is too heavy for Goldplay
}

figure {
    margin: 0;
}

hr {
    border: $textColor solid 0.1em;

    &.heart {
        position: relative;
        border-color: $alternativeColor;
        border-width: 1px;
        overflow: visible;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            color: $alternativeColor;
            font-family: 'Font Awesome 6 Pro', serif;
            font-weight: 600;
            transform: translate(50%, -50%);
            content: '\f004';
        }
    }
}

ol, ul, dl {
    margin: 0 0 1em 0;
}

p {
    margin: 0 0 1em;
}

iframe {
    border: 0;
}

// stylelint-disable declaration-no-important
// we want to make sure no rules interfere with the screen reader rules
.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px; // avoid overflow issues of parent containers (for some browsers only)
    border-width: 0 !important;
    overflow: hidden !important;
    color: #000; // make colour contrast checker happy
    background-color: $white;
    white-space: nowrap !important; // preserve spaces so screen reader vocalisation is correct
    text-transform: none !important; // do not confuse screen readers with upper-case letters
    clip: rect(0, 0, 0, 0) !important;
    clip-path: inset(50%) !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    width: auto !important;
    height: auto !important;
    margin: 0;
    overflow: visible !important;
    white-space: normal !important;
    text-transform: initial !important;
    clip: auto !important;
    clip-path: none !important;
}

.text-primary {
    color: $themeColor !important;
}

.m-0 {
    margin: 0 !important;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.w-100 {
    width: 100% !important;
}

.hide-desktop {
    @include medium-or-larger {
        display: none !important;
    }
}

.hide-mobile {
    @include medium-or-smaller {
        display: none !important;
    }
}

.text-center {
    text-align: center !important;
}
// stylelint-enable declaration-no-important

.center {
    text-align: center;
}

.max-100vw {
    max-width: 100vw;
}

.left {
    text-align: left;
}

a.bold-link {
    font-weight: 700;
    text-decoration: none;

    @include large-or-larger {
        white-space: nowrap;
    }

    @include large-or-smaller {
        hyphens: auto;
    }

    &:hover {
        text-decoration: underline;
    }

    &.icon-left i {
        margin-right: 0.5rem;
    }

    &.icon-right i {
        margin-left: 0.5rem;
    }
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-hidden {
    overflow: hidden;
}
