/*
 * Goldplay Alt
 */

@font-face {
    src: url('../fonts/GoldplayAltSemiBold/font.woff2') format('woff2'), url('../fonts/GoldplayAltSemiBold/font.woff') format('woff');
    font-family: 'GoldplayAlt';
    font-weight: 600;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/GoldplayAltBold/font.woff2') format('woff2'), url('../fonts/GoldplayAltBold/font.woff') format('woff');
    font-family: 'GoldplayAlt';
    font-weight: 700;
    font-display: swap;
}

/*
 * Goldplay
 */

@font-face {
    src: url('../fonts/GoldplayRegular/font.woff2') format('woff2'), url('../fonts/GoldplayRegular/font.woff') format('woff');
    font-family: 'Goldplay';
    font-weight: 400;
    font-display: swap;
}

@font-face {
    src: url('../fonts/GoldplaySemiBold/font.woff2') format('woff2'), url('../fonts/GoldplaySemiBold/font.woff') format('woff');
    font-family: 'Goldplay';
    font-weight: 600;
    font-display: fallback;
}

/*
 * Goldplay (italic)
 */

@font-face {
    src: url('../fonts/GoldplayRegularIt/font.woff2') format('woff2'), url('../fonts/GoldplayRegularIt/font.woff') format('woff');
    font-family: 'Goldplay';
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/GoldplaySemiBoldIt/font.woff2') format('woff2'), url('../fonts/GoldplaySemiBoldIt/font.woff') format('woff');
    font-family: 'Goldplay';
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
}
