.recipe-search-filter {
    margin-top: pxToRem(40);

    select {
        width: 100%;

        @include small-or-larger {
            width: auto;
        }
    }
}

.panel-recipe {
    h3 {
        display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
        margin-top: 1.5em;
        overflow: hidden;
        color: $themeColor;
        font-size: pxToRem(22);
        text-align: center;
        -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
        -webkit-line-clamp: 2;

        a {
            text-transform: none;
        }
    }

    .panel-content {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: 2rem;

        img {
            width: auto;
            max-width: 60px;
            transform: none;
        }
    }
}
