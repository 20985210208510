/*!
 * Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@import 'functions';
@import 'variables';

:root, :host {
    --#{$fa-css-prefix}-style-family-classic: '#{ $fa-style-family }';
    --#{$fa-css-prefix}-font-light: normal 300 1em/1 '#{ $fa-style-family }';
}

@font-face {
    src: url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'),
        url('#{$fa-font-path}/fa-light-300.ttf') format('truetype');
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    font-style: normal;
    font-display: $fa-font-display;
}

.fal,
.#{$fa-css-prefix}-light {
    font-weight: 300;
}
