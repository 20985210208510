@use 'sass:map';
@use 'sass:math';

@mixin small {
    @media (min-width: #{$breakpointSmall}) and (max-width: #{$breakpointMedium - pxToRem(1)}) {
        @content;
    }
}

@mixin medium-or-smaller-landscape {
    @media (max-width: #{$breakpointMedium - pxToRem(1)}) and (orientation: landscape) {
        @content;
    }
}

@mixin tiny-or-larger {
    @media (min-width: #{$breakpointTiny}) {
        @content;
    }
}

@mixin very-small-or-larger {
    @media (min-width: #{$breakpointVerySmall}) {
        @content;
    }
}

@mixin small-or-larger {
    @media (min-width: #{$breakpointSmall}) {
        @content;
    }
}

@mixin medium-or-larger {
    @media (min-width: #{$breakpointMedium}) {
        @content;
    }
}

@mixin very-small-or-smaller {
    @media (max-width: #{$breakpointVerySmall}) {
        @content;
    }
}

@mixin small-or-smaller {
    @media (max-width: #{$breakpointSmall}) {
        @content;
    }
}

@mixin medium-or-smaller {
    @media (max-width: #{$breakpointMedium}) {
        @content;
    }
}

@mixin large-or-smaller {
    @media (max-width: #{$breakpointLarge - pxToRem(1)}) {
        @content;
    }
}

@mixin extra-large-or-smaller {
    @media (max-width: #{$breakpointExtraLarge - pxToRem(1)}) {
        @content;
    }
}

@mixin large-or-larger {
    @media (min-width: #{$breakpointLarge}) {
        @content;
    }
}

@mixin extra-large-or-larger {
    @media (min-width: #{$breakpointExtraLarge}) {
        @content;
    }
}

@mixin huge-or-larger {
    @media (min-width: #{$breakpointHuge}) {
        @content;
    }
}

@mixin menu-large-or-smaller {
    @media (max-width: #{$breakpointMenuLarge - pxToRem(1)}) {
        @content;
    }
}

@mixin menu-extra-large-or-larger {
    @media (min-width: #{$breakpointMenuExtraLarge}) {
        @content;
    }
}

@mixin between-extra-large-and-huge {
    @media (min-width: #{$breakpointExtraLarge}) and (max-width: #{$breakpointHuge - pxToRem(1)}) {
        @content;
    }
}

@mixin font($family, $weight) {
    @if not map.has-key($fontFamilies, $family) {
        @error 'Font family "#{$family}" is not declared in font configuration variable.';
    }

    @if not map.has-key($fontWeights, $family) {
        @error 'Font family "#{$family}" has no weights declared in font configuration variable.';
    }

    $familyFontWeights: map-get($fontWeights, $family);

    @if not map.has-key($familyFontWeights, $weight) {
        @error 'Font family "#{$family}" has no dclaration for weight "#{$weight}" in font configuration variable.';
    }

    font-family: unquote(map-get($fontFamilies, $family));
    font-weight: map-get($familyFontWeights, $weight);

    @if map-get($fontLetterSpacing, $family) {
        letter-spacing: map-get($fontLetterSpacing, $family);
    }
}

@mixin focus {
    outline: none;
}

@mixin underline($hover: false) {
    text-decoration: underline;
    text-underline-offset: 0.1em;

    @if $hover {
        &:hover, &:active {
            text-decoration-thickness: 0.1em;
        }
    }
}

@mixin clampProperty($property, $minimum, $value: 0, $maximum: 0) {
    @if $maximum == 0 {
        #{$property}: map-get($minimum, 'value'); // for IE11
        #{$property}: clamp(map-get($minimum, 'minimum'), map-get($minimum, 'value'), map-get($minimum, 'maximum'));
    }
    @else {
        #{$property}: $value; // for IE11
        #{$property}: clamp(#{$minimum}, #{$value}, #{$maximum});
    }
}

@mixin centerPadded {
    @include clampProperty(padding-left, $marginMobile);
    @include clampProperty(padding-right, $marginMobile);

    margin-right: auto;
    margin-left: auto;
}

@mixin centerPaddedHorizontallyConstrained {
    @include centerPadded();

    max-width: pxToRem(1600);
}

@mixin content-ul {
    margin: 0 0 1em 0;
    padding: 0;
    list-style: none;

    & > li {
        padding: 0 0 0.5em 1.25em;
        text-indent: -1.2em;

        &:last-child {
            padding-bottom: 0;
        }

        &::before {
            display: inline-block;
            width: 0.7em;
            height: 0.7em;
            margin-right: 0.5em;
            background: url(../images/heart.svg) no-repeat;
            vertical-align: middle;
            content: '';
        }
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        display: block;
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
        content: '';
    }

    & > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
