.dropdown {
    position: relative;

    .dropdown-toggle {
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        border-bottom: 2px solid $black;
        padding: 0 pxToRem(35) 0 0;
        background: transparent;
        font-size: pxToRem(18);
        line-height: pxToRem(38);
        appearance: none;

        i {
            margin-right: pxToRem(14);
            font-size: pxToRem(25);
        }

        &::after {
            position: absolute;
            right: 0;
            margin: 0;
            border: none;
            font-family: 'Font Awesome 6 Pro', serif;
            font-size: pxToRem(25);
            font-weight: 300;
            content: '\f078';
        }
    }

    .dropdown-content {
        position: absolute;
        z-index: 1;
        display: none;
        margin-top: pxToRem(3);
        border: 1px solid $borderColorDarker;
        padding: pxToRem(20);
        background-color: $white;
        white-space: nowrap;
    }

    &.open {
        .dropdown-toggle {
            border-color: $themeColor;
            color: $themeColor;

            &::after {
                transform: rotate(180deg);
            }
        }

        .dropdown-content {
            display: block;
        }
    }
}
